import { MutableRefObject, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineDownload, HiPencilAlt, HiX } from 'react-icons/hi';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import NoResult from 'components/common/noResult';
import DateInput from 'components/input/dateInput/DateInput';
import { AbilityContext } from 'contexts/can';
import moment from 'moment';
import {
  ABILITY_ACTION,
  FORMAT_DATE_API,
  FORMAT_DATE_PICKER,
  FORMAT_DATE_SHOW_TABLE,
  INVESTMENT_PAYMENT_STATUS,
  PERMISSION,
  USER_ROLES,
} from 'utils/constants';
import { InvestmentPaymentsType, InvestmentType } from 'utils/proptypes';
import { convertNumberToCurrency, convertNumberToCurrencyWithoutUnit, getNumberValueFromApi, openStaticFile } from 'utils/utils';

import {
  downloadInvestmentPayments,
  downloadInvestmentPaymentsWithDonation,
  DownloadInvestmentPaymentType,
  DownloadInvestmentPaymentWithDonationType,
} from '../../../../../../../../api/supporter/investmentPayment';
import CustomSpinner from '../../../../../../../../components/common/customSpinner';
import TooltipComponent from '../../../../../../../../components/common/Tooltip';
import CurrencyInput from '../../../../../../../../components/input/currencyInput/CurrencyInput';
import ConfirmModal from '../../../../../../../../components/modal/common/confirmModal';
import { userSelector } from '../../../../../../../../redux/selectors';

type RenderPaymentsTableScheduleDataType = {
  investment: InvestmentType;
  paymentsSchedule: InvestmentPaymentsType[];
  editPaymentHandler: Function;
  handleDownloadCashOutInvestmentPaymentPDF: Function;
  handleDownloadFinalInvestmentPDF: Function;
  isFetching: boolean;
  tbl: MutableRefObject<null>;
  editInvestmentPaymentHandler: Function;
  deletePaymentHandler?: Function;
};

const RenderPaymentsTableScheduleData = (props: RenderPaymentsTableScheduleDataType) => {
  const ability = useContext(AbilityContext);

  const {
    paymentsSchedule = [],
    isFetching = false,
    tbl,
    editPaymentHandler,
    handleDownloadCashOutInvestmentPaymentPDF,
    investment,
    handleDownloadFinalInvestmentPDF,
    editInvestmentPaymentHandler,
    deletePaymentHandler,
  } = props;

  const { userInfo } = useSelector(userSelector);
  const { userRole } = userInfo;
  const [t] = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const supporter = investment?.supporter;

  const receivedPayments: InvestmentPaymentsType[] = useMemo(() => paymentsSchedule?.filter(item => !!item.name), [paymentsSchedule]);

  const { mutate: downloadPDFMutation, isLoading: isDownloadingPDF } = useMutation(downloadInvestmentPayments, {
    onSuccess: res => {
      openStaticFile(res?.data?.download_url);
    },
  });

  const { mutate: downloadPDFWithDonationMutation, isLoading: isDownloadingPDFWithDonation } = useMutation(downloadInvestmentPaymentsWithDonation, {
    onSuccess: res => {
      openStaticFile(res?.data?.download_url);
    },
  });

  const handleDownloadPDF = async (payment: InvestmentPaymentsType) => {
    const commonPayload = {
      CurrentDate: moment().format(FORMAT_DATE_SHOW_TABLE),
      FirstName: supporter?.firstName ? supporter?.firstName : '',
      LastName: supporter?.lastName ? supporter?.lastName : '',
      Salutation: supporter?.prefixTitles ? `${supporter?.prefixTitles}.` : '',
      Organization: supporter?.organizationName ? supporter?.organizationName : '',
      Address: supporter?.contacts?.[0]?.address?.[0]?.street ? supporter?.contacts?.[0]?.address?.[0]?.street : '',
      City: supporter?.contacts?.[0]?.address?.[0]?.city ? supporter?.contacts?.[0]?.address?.[0]?.city : '',
      State: supporter?.contacts?.[0]?.address?.[0]?.state ? supporter?.contacts?.[0]?.address?.[0]?.state : '',
      Zip: supporter?.contacts?.[0]?.address?.[0]?.zip ? supporter?.contacts?.[0]?.address?.[0]?.zip : '',
    };

    const downloadPdfPayload: DownloadInvestmentPaymentType = {
      ...commonPayload,
      RolloverAmount: payment?.amountReceived
        ? (convertNumberToCurrencyWithoutUnit(getNumberValueFromApi(payment?.amountReceived ?? 0))?.toString() as string)
        : '',
      InterestRate: investment?.interestRate
        ? (convertNumberToCurrencyWithoutUnit(getNumberValueFromApi(investment?.interestRate))?.toString() as string)
        : '',
      OutstandingPrincipal: investment?.amount
        ? (convertNumberToCurrencyWithoutUnit(getNumberValueFromApi(investment?.amount))?.toString() as string)
        : '',
    };

    const downloadPdfWithDonationPayload: DownloadInvestmentPaymentWithDonationType = {
      ...commonPayload,
      DonationAmount: payment?.interestReceived
        ? (convertNumberToCurrencyWithoutUnit(getNumberValueFromApi(payment?.interestReceived ?? 0))?.toString() as string)
        : '',
      LoanAmount: investment?.amount ? (convertNumberToCurrencyWithoutUnit(getNumberValueFromApi(investment?.amount))?.toString() as string) : '',
      InterestRate: investment?.interestRate
        ? (convertNumberToCurrencyWithoutUnit(getNumberValueFromApi(investment?.interestRate))?.toString() as string)
        : '',
    };
    switch (investment?.interestOption) {
      case 'Roll-Over':
        downloadPDFMutation(downloadPdfPayload);
        break;
      case 'Donate':
        downloadPDFWithDonationMutation(downloadPdfWithDonationPayload);
    }
  };

  const checkFinalInvestment = () => {
    if (investment && paymentsSchedule) {
      let amountReceived = 0;
      let checkAmountReceived = 0;
      paymentsSchedule.forEach(payment => {
        if (payment.name && payment.status === 'Paid') amountReceived += payment.amountReceived;
      });

      paymentsSchedule.forEach(payment => {
        if (!payment.name && payment.status === 'Unpaid') checkAmountReceived += payment.amountReceived;
      });

      return checkAmountReceived === amountReceived;
    } else return false;
  };

  const handleOpenConfirmModal = (payment: any) => {
    setOpenModal(true);
    setSelectedPayment(payment);
  };

  const handleDeletePayment = (payment: any) => {
    if (deletePaymentHandler) {
      deletePaymentHandler(payment?.id);
      setSelectedPayment(null);
    }
  };

  return (
    <table
      className={classNames('w-full text-sm text-left text-gray-500 dark:text-gray-400', { 'h-full': (paymentsSchedule?.length as number) === 0 })}
      ref={tbl}
    >
      <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400 rounded">
        <tr>
          <th scope="col" className="py-3 px-4 max-width-108 investment-payment--truncate">
            {t('paymentDate')}
          </th>
          <th scope="col" className="py-3 px-4 max-width-108 investment-payment--truncate hidden-mobile">
            {t('daysSinceLastTrans')}
          </th>
          <th scope="col" className="py-3 px-4 max-width-108 investment-payment--truncate hidden-mobile-tablet">
            {t('principalToPay')}
          </th>
          <th scope="col" className="py-3 px-4 max-width-108 investment-payment--truncate hidden-mobile-tablet">
            {t('interestToPay')}
          </th>
          <th scope="col" className="py-3 px-4 max-width-108 investment-payment--truncate hidden-mobile-tablet">
            {t('totalToPay')}
          </th>
          <th scope="col" className="py-3 px-4 max-width-108 investment-payment--truncate hidden-mobile">
            {t('balance')}
          </th>
          {ability.can(ABILITY_ACTION.manage, PERMISSION.INVESTMENT_PAYMENT.VIEW) && paymentsSchedule?.filter(payment => payment.name)?.length ? (
            <th scope="col" className="py-3 px-4 investment-payment--truncate" />
          ) : (
            ''
          )}
        </tr>
      </thead>
      <tbody className="text-xs">
        {paymentsSchedule?.length > 0 ? (
          paymentsSchedule?.map((investmentPayment: Partial<InvestmentPaymentsType>, key: number) => {
            return (
              <tr
                key={`${key}-list-investmentPayment`}
                className={classNames(
                  `investmentPayment-${
                    key === paymentsSchedule?.length - 1 ? 'last' : key
                  } border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600`,
                  { 'bg-gray-100': investmentPayment?.name },
                )}
              >
                {!investmentPayment?.transactionDays || investmentPayment?.status === INVESTMENT_PAYMENT_STATUS.Paid ? (
                  <th scope="row" className="py-1 px-4 font-normal text-gray-900">
                    <DateInput
                      dateFormat={FORMAT_DATE_PICKER}
                      defaultValue={investmentPayment?.dueDate ? investmentPayment?.dueDate : undefined}
                      selectItemsHandler={(date: Date) => {
                        if (ability.can(ABILITY_ACTION.manage, PERMISSION.INVESTMENT_PAYMENT.UPDATE))
                          editPaymentHandler({
                            id: investmentPayment?.id,
                            dueDate: date,
                          });
                      }}
                      isClearable={false}
                      filterDate={(date: Date) => {
                        return (
                          date > moment(moment.utc(paymentsSchedule?.[key - 1]?.dueDate).format(FORMAT_DATE_API))?.toDate() &&
                          date < moment(moment.utc(paymentsSchedule?.[key + 1]?.dueDate).format(FORMAT_DATE_API))?.toDate()
                        );
                      }}
                    />
                  </th>
                ) : (
                  <th scope="row" className="py-1 px-4 font-normal text-gray-900">
                    <DateInput
                      dateFormat={FORMAT_DATE_PICKER}
                      defaultValue={investmentPayment?.dueDate ? investmentPayment?.dueDate : undefined}
                      selectItemsHandler={(date: Date) => {
                        if (ability.can(ABILITY_ACTION.manage, PERMISSION.INVESTMENT_PAYMENT.UPDATE))
                          editPaymentHandler({
                            id: investmentPayment?.id,
                            dueDate: date,
                          });
                      }}
                      isClearable={false}
                      filterDate={(date: Date) => {
                        return (
                          date > moment(moment.utc(paymentsSchedule?.[key - 1]?.dueDate).format(FORMAT_DATE_API))?.toDate() &&
                          date < moment(moment.utc(paymentsSchedule?.[key + 1]?.dueDate).format(FORMAT_DATE_API))?.toDate()
                        );
                      }}
                    />
                  </th>
                )}
                {investmentPayment?.name && investmentPayment?.checkNumber ? (
                  <th scope="row" className="py-1 px-4 font-normal text-gray-900">
                    {`${t('checkNumber')}: ${investmentPayment?.checkNumber}`}
                  </th>
                ) : (
                  <th
                    scope="row"
                    className="py-3 px-4 font-normal text-gray-900 investment-payment--truncate dark:text-white max-width-108 hidden-mobile"
                  >
                    {investmentPayment?.transactionDays ? `${investmentPayment?.transactionDays} ${t('days')}` : ''}
                  </th>
                )}
                <th
                  scope="row"
                  className="py-3 px-4 font-normal text-gray-900 investment-payment--truncate dark:text-white max-width-108 hidden-mobile-tablet"
                >
                  {investmentPayment?.principalReceived && !investmentPayment?.name
                    ? convertNumberToCurrency(getNumberValueFromApi(investmentPayment?.principalReceived))
                    : ''}
                </th>
                <th
                  scope="row"
                  className="py-3 px-4 font-normal text-gray-900 investment-payment--truncate dark:text-white max-width-108 hidden-mobile-tablet"
                >
                  {investmentPayment?.interestReceived && !investmentPayment?.name
                    ? convertNumberToCurrency(getNumberValueFromApi(investmentPayment.interestReceived))
                    : ''}
                </th>
                <th
                  scope="row"
                  className="py-3 px-4 font-normal text-gray-900 investment-payment--truncate dark:text-white max-width-108 hidden-mobile-tablet"
                >
                  {investmentPayment?.name && (
                    <CurrencyInput
                      defaultValue={investmentPayment?.amountReceived ? getNumberValueFromApi(investmentPayment?.amountReceived) : undefined}
                      changeValueHandler={(paymentReceivedAmount: number) => {
                        if (ability.can(ABILITY_ACTION.manage, PERMISSION.INVESTMENT_PAYMENT.UPDATE))
                          editPaymentHandler({
                            id: investmentPayment?.id,
                            amountReceived: paymentReceivedAmount,
                          });
                      }}
                      prefix="$"
                      decimalLimit={2}
                      isDisabled={!ability.can(ABILITY_ACTION.manage, PERMISSION.PAYMENT.UPDATE)}
                    />
                  )}
                  {investmentPayment?.amountReceived && !investmentPayment?.name
                    ? convertNumberToCurrency(getNumberValueFromApi(investmentPayment?.amountReceived))
                    : ''}
                </th>
                <th
                  scope="row"
                  className="py-3 px-4 font-normal text-gray-900 investment-payment--truncate dark:text-white max-width-108 hidden-mobile"
                >
                  {investmentPayment?.balance ? convertNumberToCurrency(getNumberValueFromApi(investmentPayment?.balance)) : ''}
                </th>
                {ability.can(ABILITY_ACTION.manage, PERMISSION.INVESTMENT_PAYMENT.VIEW) &&
                  investmentPayment.investment?.interestOption === 'Cash-Out' &&
                  investmentPayment?.status === INVESTMENT_PAYMENT_STATUS.Paid && (
                    <th scope="row" className="flex justify-end py-3 px-4 font-normal text-gray-900 investment-payment--truncate hidden-mobile">
                      {investmentPayment?.name && investmentPayment?.interestReceived ? (
                        <div className="flex"> <button
                          className="download-pdf-button cursor-pointerpy-1text-white bg-blue-900 rounded-lg"
                          onClick={() => handleDownloadCashOutInvestmentPaymentPDF(investmentPayment.id)}
                        >
                          <HiOutlineDownload className="h-8 w-8" />
                        </button>
                        {userRole?.includes(USER_ROLES.SUPER_ADMIN) && <button
                            onClick={() => handleOpenConfirmModal(investmentPayment)}
                            className="delete-payment-button cursor-pointer text-white bg-red-700 rounded-lg"
                          >
                            <HiX className="h-8 w-8" />
                          </button>}
                        </div>
                      ) : (
                        ''
                      )}
                    </th>
                  )}
                {ability.can(ABILITY_ACTION.manage, PERMISSION.INVESTMENT_PAYMENT.VIEW) &&
                (investment?.interestOption === 'Roll-Over' || investment?.interestOption === 'Donate') &&
                paymentsSchedule?.filter(payment => payment.name)?.length ? (
                  <th
                    scope="row"
                    className={classNames('flex justify-end py-3 px-4 font-normal text-gray-900 investment-payment--truncate hidden-mobile', {
                      'animate-pulse': isDownloadingPDF || isDownloadingPDFWithDonation,
                    })}
                  >
                    {investmentPayment?.name &&
                    investmentPayment?.status === INVESTMENT_PAYMENT_STATUS.Paid &&
                    investmentPayment?.interestReceived ? (
                      <div className="flex">
                        <button
                          className="download-pdf-button cursor-pointerpy-1text-white bg-blue-900 rounded-lg"
                          disabled={isDownloadingPDF || isDownloadingPDFWithDonation}
                          onClick={() => handleDownloadPDF(investmentPayment as InvestmentPaymentsType)}
                        >
                          <HiOutlineDownload className="h-8 w-8" />
                        </button>
                        <button
                          onClick={() => handleOpenConfirmModal(investmentPayment)}
                          className="delete-payment-button cursor-pointer text-white bg-red-700 rounded-lg"
                        >
                          <HiX className="h-8 w-8" />
                        </button>
                      </div>
                    ) : (
                      ''
                    )}
                  </th>
                ) : (
                  ''
                )}
              </tr>
            );
          })
        ) : (
          <tr className="h-full investmentPayment-no-result text-center bg-white">
            <th colSpan={8}>{isFetching ? <CustomSpinner /> : <NoResult />}</th>
          </tr>
        )}

        {ability.can(ABILITY_ACTION.manage, PERMISSION.INVESTMENT_PAYMENT.VIEW) && checkFinalInvestment() && (
          <tr className="dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 bg-gray-200 bg-green-50 ">
            <th scope="row" colSpan={6}>
              <div className="final-investment-text py-3 px-4 font-normal payment--truncate max-w-full dark:text-grey">{t('investmentMatured')}</div>
            </th>
            <th scope="row" className="flex justify-end py-3 px-4 hidden-mobile">
              <button
                className="download-pdf-button cursor-pointerpy-1text-white bg-blue-900 rounded-lg"
                onClick={() => handleDownloadFinalInvestmentPDF(investment.id)}
              >
                <HiOutlineDownload className="h-8 w-8" />
              </button>
            </th>
          </tr>
        )}
      </tbody>
      {openModal && (
        <ConfirmModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          typeEvent={'delete'}
          title={t('payment')}
          confirmFunction={() => handleDeletePayment(selectedPayment)}
        />
      )}
    </table>
  );
};

export default RenderPaymentsTableScheduleData;
