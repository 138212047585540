import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBar from 'react-top-loading-bar';
import { getInvestmentPayments } from 'api/supporter/investmentPayment';
import { downloadSupporterContact, exportSupportersToCSV, getSupportersByFilter } from 'api/supporter/supporter';
import SelectDateRangeModal from 'components/modal/common/selectDateRange';
import AddSupporterModal from 'components/modal/supporter/addSupporterModal';
import HeaderTable from 'components/table/headerTable';
import PaginateTable from 'components/table/paginate';
import useLoading from 'hooks/useLoading';
import moment from 'moment';
import {
  DEFAULT_VALUE_FILTER,
  EXPORT_FILE_NAME,
  FORMAT_DATE_API,
  FORMAT_DATE_SHOW_TABLE,
  FORMAT_MONTH_API,
  ROUTE_PATHS,
  SUPPORTER_PREFERRED_COMMUNICATION_METHOD_OPTIONS,
  SUPPORTER_PREFERRED_PAYMENT_METHOD,
  SUPPORTER_TYPE_OPTIONS,
} from 'utils/constants';
import {
  FilterDownloadUpcomingPaymentsValueType,
  FilterType,
  FilterUpcomingPaymentsValueType,
  InvestmentPaymentsType,
  SupporterFilterValueType,
  SupporterType,
  UpcomingPaymentsType,
} from 'utils/proptypes';
import {
  checkInterestOrPrincipalPayment,
  concatName,
  convertNumberToCurrency,
  exportRowDataToCSV,
  getNumberValueFromApi,
  messageErrors,
} from 'utils/utils';
import { read, utils } from 'xlsx';

import RecordPaymentMethodModal from '../../../components/modal/supporter/recordPaymentMethodModal';

import HeaderTableSupporterType from './components/headerSupporterTable';
import RenderUpcomingPayments from './components/RenderUpcomingPayments';
import { RenderSupportersTableData } from './components';

import '../../../styles/styles.scss';
import './supporterManager.scss';

const SupporterManagerPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState(DEFAULT_VALUE_FILTER.SEARCH_QUERY);
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE_FILTER.PAGE);
  const [totalEntities, setTotalEntities] = useState(0);
  const limit = 10;
  const [supporters, setSupporters] = useState<SupporterType[]>([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [isOpenRangeDateModal, setIsOpenRangeDateModal] = useState(false);
  const [isOpenRecordPaymentConfirmModal, setIsOpenRecordPaymentConfirmModal] = useState(false);
  const [selectedInvestmentPayment, setSelectedInvestmentPayment] = useState<Partial<InvestmentPaymentsType>>();
  const [filterInvestmentPaymentsValue, setFilterInvestmentPaymentsValue] = useState<FilterUpcomingPaymentsValueType>({
    fPaymentDate: moment().startOf('month').format(FORMAT_MONTH_API),
  });
  const [filterDownloadInvestmentPaymentsValue] = useState<FilterDownloadUpcomingPaymentsValueType>({
    fStartDate: moment().startOf('month').format(FORMAT_DATE_API),
    fEndDate: moment().add(59, 'months').endOf('month').format(FORMAT_DATE_API),
  });

  const [__html, setHtml] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const tbl = useRef(null);
  const ref = useRef(null);
  const tblUpcomingPayment = useRef(null);

  const [filterSupportersValue, setFilterSupportersValue] = useState<Partial<SupporterFilterValueType>>({
    fType: undefined,
    fPreferredCommunicationMethod: undefined,
  });

  const filterArray: FilterType[] = [
    { name: 'fType', placeholder: t('supporterType'), type: 'select', options: SUPPORTER_TYPE_OPTIONS },
    {
      name: 'fPreferredCommunicationMethod',
      placeholder: t('communicationType'),
      type: 'select',
      options: SUPPORTER_PREFERRED_COMMUNICATION_METHOD_OPTIONS,
    },
  ];

  const { refetch: refetchSupporters, isFetching: isFetchingSupporters } = useQuery(
    ['supporters', currentPage, searchValue, filterSupportersValue],
    () => getSupportersByFilter({ page: currentPage, limit, searchQuery: searchValue, ...filterSupportersValue }),
    {
      onSuccess: ({ data }) => {
        setTotalEntities(data.totalEntities);
        setSupporters(data.entities);
        const f = new ArrayBuffer(data.entities);
        const wb = read(f);
        const ws = wb.Sheets[wb.SheetNames[0]];
        const dataPartner = utils.sheet_to_html(ws);
        setHtml(dataPartner);
      },
      onError: () => {
        setSupporters([]);
        setTotalEntities(0);
      },
    },
  );

  const {
    data: investmentPaymentsData,
    refetch: refetchInvestmentPayments,
    isFetching: isFetchingInvestmentPayments,
  } = useQuery(['investmentPayments', filterInvestmentPaymentsValue], () => getInvestmentPayments({ ...filterInvestmentPaymentsValue }));

  const addPartnerHandler = () => {
    setOpenAddModal(!openAddModal);
  };

  const openSupporterDetail = (item: SupporterType) => {
    navigate(`${ROUTE_PATHS.SUPPORTER_MANAGER_PAGE}/${item?.id}`);
  };

  const exportSupporterContact = async () => {
    downloadSupporterContact(filterSupportersValue).then(res => {
      if (res.status === 200)
        exportRowDataToCSV({
          stream: res.data,
          fileName: EXPORT_FILE_NAME.ALL_SUPPORTER_CONTACT,
        });
    });
  };

  const handleExportSupporters = async () => {
    await exportSupportersToCSV({ ...filterSupportersValue, searchQuery: searchValue }).then(async res => {
      exportRowDataToCSV({ stream: res.data, fileName: EXPORT_FILE_NAME.SUPPORTER });
    });
  };

  const { refetch: refetchExportSupporters, isFetching: isFetchingDownloadSupporters } = useQuery('download-supporters', handleExportSupporters, {
    onSuccess: () => {
      const message: string = t('supporterPage.exportToCsvSuccess');
      toast.success(message);
    },
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
    enabled: false,
  });

  useLoading({ isLoading: isFetchingSupporters && isFetchingInvestmentPayments, ref });

  const upcomingPayments: UpcomingPaymentsType[] = useMemo(() => {
    const investmentPayments: InvestmentPaymentsType[] = investmentPaymentsData?.data.entities;

    return investmentPayments?.map((investmentPayment: InvestmentPaymentsType) => ({
      paymentId: investmentPayment?.id,
      investmentId: investmentPayment?.investmentId,
      amountReceived: investmentPayment?.amountReceived,
      checkNumber: investmentPayment?.checkNumber && investmentPayment?.checkNumber ? investmentPayment.checkNumber : '',
      preferredPaymentMethod: investmentPayment?.investment?.supporter?.preferredPaymentMethod as keyof typeof SUPPORTER_PREFERRED_PAYMENT_METHOD,
      supporterId: investmentPayment?.investment?.supporter?.id,
      supporterName: concatName({
        firstName: investmentPayment?.investment?.supporter?.firstName as string,
        lastName: investmentPayment?.investment?.supporter?.lastName as string,
      }),
      organizationName: investmentPayment?.investment?.supporter?.organizationName,
      paymentType: checkInterestOrPrincipalPayment({
        principalReceived: investmentPayment?.principalReceived,
        interestReceived: investmentPayment?.interestReceived,
      }),
      interestPaymentType: investmentPayment?.investment?.interestOption,
      paymentCode: investmentPayment?.investment?.name ? investmentPayment?.investment?.name : '',
      dueDate: investmentPayment?.dueDate ? moment.utc(investmentPayment?.dueDate).format(FORMAT_DATE_SHOW_TABLE) : '',
      receivedDate: investmentPayment?.receivedDate ? moment.utc(investmentPayment?.receivedDate).format(FORMAT_DATE_SHOW_TABLE) : '',
      amount: investmentPayment?.amountReceived ? convertNumberToCurrency(getNumberValueFromApi(investmentPayment?.amountReceived)) : '',
      status: investmentPayment?.status ? investmentPayment?.status : '',
    }));
  }, [investmentPaymentsData]);


  return (
    <>
      <LoadingBar color="#a1c93a" ref={ref} shadow={true} containerStyle={{ height: '3px' }} />
      <div className="w-full supporter-page">
        <HeaderTable
          setCurrentPage={setCurrentPage}
          placeholderSearch={t('findASupporterInvestment')}
          nameButton={t('addSupporter')}
          setSearchValue={setSearchValue}
          addHandler={addPartnerHandler}
          exportHandler={exportSupporterContact}
          hideSearch={true}
          hideExportButton={false}
          className="supporter"
          abilityObjectTitle="SUPPORTER"
          isSupporterHeader={true}
        />
        <div className="supporter-page__container h-full flex w-full items-start space-x-3 px-3 mb-3">
          <div className="upcomingPayments-table w-1/3 flex flex-col h-full bg-white">
            <RenderUpcomingPayments
              upcomingPayments={upcomingPayments}
              tbl={tblUpcomingPayment}
              refetchInvestmentPayments={refetchInvestmentPayments}
              setFilterInvestmentPaymentsValue={setFilterInvestmentPaymentsValue}
              setIsOpenRecordPaymentConfirmModal={setIsOpenRecordPaymentConfirmModal}
              setSelectedInvestmentPayment={setSelectedInvestmentPayment}
              isFetching={isFetchingInvestmentPayments}
            />
            <PaginateTable
              className="supporter-table__pagination"
              totalEntities={upcomingPayments?.length ?? 0}
              hidePaginate={true}
              setIsOpenRangeDateModal={setIsOpenRangeDateModal}
              isFetchingDownload={isFetchingDownloadSupporters}
            />
          </div>
          <div className="supporter-table w-2/3 flex flex-col h-full bg-white">
            <HeaderTableSupporterType<SupporterFilterValueType>
              className="supporter-table"
              hideFilter={false}
              hideSearch={false}
              setCurrentPage={setCurrentPage}
              setSearchValue={setSearchValue}
              setFilterValue={setFilterSupportersValue}
              filterArray={filterArray}
              placeholderSearch={t('findASupporterInvestment')}
            />
            <RenderSupportersTableData
              tbl={tbl}
              supporters={supporters}
              openSupporterDetail={openSupporterDetail}
              isFetching={isFetchingSupporters}
            />
            {totalEntities > 0 && (
              <PaginateTable
                className="supporter-table__pagination"
                limit={limit}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                totalEntities={totalEntities}
                isFetching={isFetchingSupporters}
                isFetchingDownload={isFetchingDownloadSupporters}
                exportHandler={refetchExportSupporters}
                items={supporters}
              />
            )}
          </div>
        </div>
      </div>
      <AddSupporterModal
        openModal={openAddModal}
        setOpenModal={setOpenAddModal}
        refetchApi={refetchSupporters}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />
      <SelectDateRangeModal
        openModal={isOpenRangeDateModal}
        setOpenModal={setIsOpenRangeDateModal}
        advanceFilter={filterDownloadInvestmentPaymentsValue}
      />
      <RecordPaymentMethodModal
        openModal={isOpenRecordPaymentConfirmModal}
        setOpenModal={setIsOpenRecordPaymentConfirmModal}
        selectedInvestmentPayment={
          selectedInvestmentPayment as InvestmentPaymentsType & {
            preferredPaymentMethod: string;
          }
        }
        refetchInvestmentPayments={refetchInvestmentPayments}
      />
    </>
  );
};
export default SupporterManagerPage;
