import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCountries } from 'api/loanFundManager/countryApi';
import { addEditSupporterTypes, createSupporter, updateSupporterById } from 'api/supporter/supporter';
import GroupButton from 'components/button/groupButton';
import CheckboxForm from 'components/form/checkBoxForm/customCheckboxForm';
import CustomDatePickerForm from 'components/form/dateSelectForm/customDatePickerForm';
import CustomSelectForm from 'components/form/selectForm/customSelectForm';
import TextForm from 'components/form/textForm/textForm';
import { Modal } from 'flowbite-react';
import { ErrorMessage, Form, Formik, FormikProps, useFormikContext } from 'formik';
import moment from 'moment';
import {
  FORMAT_DATE_API,
  PREFIX_TITLES,
  ROUTE_PATHS,
  SUPPORTER_PREFERRED_COMMUNICATION_METHOD_OPTIONS,
  SUPPORTER_PREFERRED_PAYMENT_METHOD,
  SUPPORTER_TYPE_OPTIONS,
} from 'utils/constants';
import { OptionType, SupporterType } from 'utils/proptypes';
import { getOptions, messageErrors, selectDateHandler, selectItemHandler, setTextValue } from 'utils/utils';
import * as Yup from 'yup';

import './addSupporterModal.scss';
import '../../../styles/common/multiSelect.scss';

type AddSupporterModalTypes = {
  openModal: boolean;
  setOpenModal: Function;
  isEdit: boolean;
  setIsEdit: Function;
  selectedSupporter?: SupporterType;
  refetchApi: Function;
  isComeback?: boolean;
};

type AddSupporterFormTypes = {
  prefixTitles?: OptionType[];
  firstName: string;
  lastName: string;
  trustName?: string;
  SSN?: string;
  dateOfBirth?: Date;
  address: string;
  city: string;
  state?: string;
  zipCode?: string;
  country: OptionType[];
  homePhone?: string;
  businessPhone?: string;
  organizationName?: string;
  type?: OptionType[];
  preferredCommunicationMethod?: OptionType[];
  preferredPaymentMethod?: string;
  note: string;
  email?: string;
  bankAccountNumber?: string;
  routingNumber?: string;
};

const AddSupporterModal = (props: AddSupporterModalTypes) => {
  const { openModal = false, setOpenModal, selectedSupporter, refetchApi, isEdit = false, setIsEdit, isComeback = true } = props;

  const { t } = useTranslation();
  const [isResetForm, setIsResetForm] = useState(false);
  const navigate = useNavigate();

  const ValidateSchema = Yup.object().shape({
    prefixTitles: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string(),
        }),
      )
      .nullable(),
    firstName: Yup.string()
      .min(1, t('supporterManagerPage.nameLengthMin'))
      .max(100, t('supporterManagerPage.nameLengthMax'))
      .test({
        name: 'firstName-test',
        test(value, ctx) {
          const { createError, from }: any = ctx;
          const values: any = from[0]?.value;
          const { firstName, organizationName } = values;
          if (!(firstName || organizationName)) {
            return createError({ message: t('supporterManagerPage.requiredField') });
          }
          return true;
        },
      }),
    lastName: Yup.string()
      .min(1, t('supporterManagerPage.nameLengthMin'))
      .max(100, t('supporterManagerPage.nameLengthMax'))
      .test({
        name: 'lastName-test',
        test(value, ctx) {
          const { createError, from }: any = ctx;
          const values: any = from[0]?.value;
          const { lastName, organizationName } = values;
          if (!(lastName || organizationName)) {
            return createError({ message: t('supporterManagerPage.requiredField') });
          }
          return true;
        },
      }),
    trustName: Yup.string().min(1, t('supporterManagerPage.nameLengthMin')).max(100, t('supporterManagerPage.nameLengthMax')).nullable(),
    SSN: Yup.string().test({
      name: 'SSN-test',
      skipAbsent: true,
      test(value, ctx) {
        const { createError } = ctx;
        if (isNaN(value as any)) return createError({ message: t('supporterManagerPage.ssnRequiredNumber') });
        return true;
      },
    }),
    dateOfBirth: Yup.date().nullable(),
    email: Yup.string().email(t('userManagementPage.invalidEmail')).nullable(),
    address: Yup.string()
      .min(1, t('supporterManagerPage.nameLengthMin'))
      .max(100, t('supporterManagerPage.nameLengthMax'))
      .required(t('supporterManagerPage.requiredField')),
    city: Yup.string().max(100, t('supporterManagerPage.nameLengthMax')).required(t('supporterManagerPage.requiredField')),
    state: Yup.string().max(100, t('supporterManagerPage.nameLengthMax')).nullable(),
    zipCode: Yup.string().max(100, t('supporterManagerPage.nameLengthMax')).nullable(),
    country: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string(),
        }),
      )
      .min(1, t('supporterManagerPage.requiredField')),
    homePhone: Yup.string().min(1, t('supporterManagerPage.nameLengthMin')).max(100, t('supporterManagerPage.nameLengthMax')).nullable(),
    businessPhone: Yup.string().min(1, t('supporterManagerPage.nameLengthMin')).max(100, t('supporterManagerPage.nameLengthMax')).nullable(),
    organizationName: Yup.string()
      .min(1, t('supporterManagerPage.nameLengthMin'))
      .max(100, t('supporterManagerPage.nameLengthMax'))
      .test({
        name: 'organizationName-test',
        test(value, ctx) {
          const { createError, from }: any = ctx;
          const values: any = from[0]?.value;
          const { firstName, lastName, organizationName } = values;
          if (!organizationName && !(firstName && lastName)) {
            return createError({ message: t('supporterManagerPage.requiredField') });
          }
          return true;
        },
      }),
    type: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string(),
        }),
      )
      .nullable(),
    preferredCommunicationMethod: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string(),
        }),
      )
      .nullable(),
    preferredPaymentMethod: Yup.string().when('type', (type: any, schema: any) => {
      if (type[0]?.[0]?.value === 'Contact') {
        return schema.nullable();
      } else {
        return schema
          .oneOf([SUPPORTER_PREFERRED_PAYMENT_METHOD.ACH, SUPPORTER_PREFERRED_PAYMENT_METHOD.CHECK])
          .required(t('supporterManagerPage.requiredField'));
      }
    }),
    bankAccountNumber: Yup.string()
      .test({
        name: 'bankAccountNumber-test',
        skipAbsent: true,
        test(value, ctx) {
          const { createError } = ctx;
          if (isNaN(value as any)) return createError({ message: t('supporterManagerPage.bankAccountNumberRequiredNumber') });
          return true;
        },
      })
      .when('preferredPaymentMethod', (preferredPaymentMethod: string[], schema: any) => {
        if (preferredPaymentMethod?.[0] === SUPPORTER_PREFERRED_PAYMENT_METHOD.ACH) return schema.required(t('supporterManagerPage.requiredField'));
      }),
    routingNumber: Yup.string()
      .test({
        name: 'routingNumber-test',
        skipAbsent: true,
        test(value, ctx) {
          const { createError } = ctx;
          if (isNaN(value as any)) return createError({ message: t('supporterManagerPage.routingNumberRequiredNumber') });
          else if ((value as any)?.length !== 9) return createError({ message: t('supporterManagerPage.routingNumberMaxLength') });
          return true;
        },
      })
      .when('preferredPaymentMethod', (preferredPaymentMethod: string[], schema: any) => {
        if (preferredPaymentMethod?.[0] === SUPPORTER_PREFERRED_PAYMENT_METHOD.ACH) return schema.required(t('supporterManagerPage.requiredField'));
      }),
  });

  const { data: countries } = useQuery(['countries'], () => getCountries());

  const countryOptions = useMemo(() => {
    return countries?.data?.entities?.map((country: any) => ({
      label: country.name,
      value: country.id,
      code: country?.code,
    }));
  }, [countries]);

  const prefixTitleOptions = getOptions(PREFIX_TITLES);

  const initialValues: Partial<AddSupporterFormTypes> = useMemo(() => {
    return isEdit && selectedSupporter
      ? {
          prefixTitles: selectedSupporter?.prefixTitles ? prefixTitleOptions.filter(item => item.value === selectedSupporter?.prefixTitles) : [],
          firstName: selectedSupporter.firstName ? selectedSupporter.firstName : '',
          lastName: selectedSupporter.lastName ? selectedSupporter.lastName : '',
          trustName: selectedSupporter.trustName ? selectedSupporter.trustName : '',
          SSN: Number(selectedSupporter.SSN) ? selectedSupporter.SSN : '',
          dateOfBirth: selectedSupporter?.dateOfBirth ? moment.utc(selectedSupporter?.dateOfBirth).toDate() : undefined,
          address: selectedSupporter?.contacts
            ? selectedSupporter?.contacts.filter(contact => contact?.address?.length > 0)?.[0]?.address[0]?.street
            : '',
          city: selectedSupporter?.contacts ? selectedSupporter.contacts.filter(contact => contact?.address?.length > 0)?.[0]?.address[0]?.city : '',
          state: selectedSupporter?.contacts
            ? selectedSupporter.contacts.filter(contact => contact?.address?.length > 0)?.[0]?.address[0]?.state
            : '',
          zipCode: selectedSupporter?.contacts
            ? selectedSupporter.contacts.filter(contact => contact?.address?.length > 0)?.[0]?.address[0]?.zip
            : '',
          country: selectedSupporter?.contacts
            ? countryOptions?.filter(
                (item: OptionType) =>
                  item?.label === selectedSupporter.contacts.filter(contact => contact?.address?.length > 0)?.[0]?.address[0]?.country?.name,
              )
            : [],
          homePhone: selectedSupporter.homePhone ? selectedSupporter.homePhone : '',
          businessPhone: selectedSupporter.businessPhone ? selectedSupporter.businessPhone : '',
          organizationName: selectedSupporter.organizationName ? selectedSupporter.organizationName : '',
          preferredCommunicationMethod: selectedSupporter?.preferredCommunicationMethod
            ? SUPPORTER_PREFERRED_COMMUNICATION_METHOD_OPTIONS.filter(item => item.value === selectedSupporter?.preferredCommunicationMethod)
            : [],
          preferredPaymentMethod: selectedSupporter?.preferredPaymentMethod
            ? selectedSupporter?.preferredPaymentMethod
            : SUPPORTER_PREFERRED_PAYMENT_METHOD.CHECK,
          type: selectedSupporter.type ? SUPPORTER_TYPE_OPTIONS.filter(item => item.label === selectedSupporter?.type) : [],
          email: selectedSupporter?.email ? selectedSupporter?.email : '',
          bankAccountNumber:
            selectedSupporter?.preferredPaymentMethod === SUPPORTER_PREFERRED_PAYMENT_METHOD.ACH && Number(selectedSupporter?.bankAccountNumber)
              ? selectedSupporter?.bankAccountNumber
              : '',
          routingNumber:
            selectedSupporter?.preferredPaymentMethod === SUPPORTER_PREFERRED_PAYMENT_METHOD.ACH && selectedSupporter?.routingNumber
              ? selectedSupporter?.routingNumber
              : '',
        }
      : {
          prefixTitles: [],
          firstName: '',
          lastName: '',
          trustName: '',
          SSN: '',
          dateOfBirth: undefined,
          address: '',
          city: '',
          country:
            countryOptions?.filter(
              (
                item: OptionType & {
                  code: string;
                },
              ) => item?.code === 'US',
            ) ?? [],
          state: '',
          zipCode: '',
          homePhone: '',
          businessPhone: '',
          organizationName: '',
          type: [],
          preferredCommunicationMethod: [],
          preferredPaymentMethod: SUPPORTER_PREFERRED_PAYMENT_METHOD.CHECK,
          email: '',
          bankAccountNumber: '',
          routingNumber: '',
          isResetForm,
        };
  }, [isEdit, selectedSupporter, countryOptions, isResetForm, prefixTitleOptions]);

  const handleAddAndUpdateSupporter = async (supporterPayload: addEditSupporterTypes) => {
    if (isEdit) {
      await updateSupporterById<addEditSupporterTypes>(selectedSupporter?.id as string, supporterPayload);
    } else {
      await createSupporter(supporterPayload);
    }
  };

  const mutation = useMutation('create-update-supporter', {
    mutationFn: handleAddAndUpdateSupporter,
  });

  const handleSubmit = (data: Partial<AddSupporterFormTypes>, action: { [key: string]: any }) => {
    const successCallback = (message: string) => {
      if (isComeback) navigate(ROUTE_PATHS.SUPPORTER_MANAGER_PAGE, { replace: true });
      setOpenModal(!openModal);
      setIsEdit(false);
      toast.success(message);
      action.resetForm();
      setIsResetForm(!isResetForm);
      refetchApi();
    };
    const defaultAddress = {
      street: data.address ? data.address : undefined,
      city: data.city ? data.city : undefined,
      state: data.state ? data.state : undefined,
      zip: data.zipCode ? data.zipCode : undefined,
      countryId: data?.country?.length ? data?.country[0]?.value : undefined,
    };

    const tempData = {
      prefixTitles: data.prefixTitles?.length ? data.prefixTitles[0].value : null,
      firstName: data.firstName ?? undefined,
      lastName: data.lastName ?? undefined,
      trustName: data.trustName ?? undefined,
      SSN: Number(data.SSN) ?? undefined,
      dateOfBirth: data.dateOfBirth ? moment(data.dateOfBirth).format(FORMAT_DATE_API) : null,
      contacts: Object.values(defaultAddress).filter(item => item).length ? [{ address: [{ ...defaultAddress }] }] : undefined,
      homePhone: data.homePhone ?? undefined,
      businessPhone: data.businessPhone ?? undefined,
      organizationName: data.organizationName ?? undefined,
      type: data.type?.length ? data.type[0].label : null,
      preferredCommunicationMethod: data.preferredCommunicationMethod?.length ? data.preferredCommunicationMethod[0].value : null,
      preferredPaymentMethod: data.preferredPaymentMethod || null,
      email: data.email ?? undefined,
      bankAccountNumber: data.bankAccountNumber
        ? !data.preferredPaymentMethod?.includes(SUPPORTER_PREFERRED_PAYMENT_METHOD.CHECK)
          ? data.bankAccountNumber
          : null
        : undefined,
      routingNumber: data.routingNumber
        ? !data.preferredPaymentMethod?.includes(SUPPORTER_PREFERRED_PAYMENT_METHOD.CHECK)
          ? data.routingNumber
          : null
        : undefined,
    };

    if (openModal) {
      mutation.mutate(
        {
          ...(tempData as any),
        },
        {
          onSuccess: () => {
            const message: string = isEdit ? t('supporterManagerPage.editSuccessMessage') : t('supporterManagerPage.createSuccessMessage');
            successCallback(message);
          },
          onError: async (error: any) => {
            const message: string = messageErrors(error, t);
            toast.error(message);
          },
        },
      );
    }
  };

  const closeModalHandler = (props: FormikProps<AddSupporterFormTypes>) => {
    setOpenModal(!openModal);
    setIsEdit(false);
    props.resetForm();
    props.setErrors({});
    if (isComeback) navigate(ROUTE_PATHS.SUPPORTER_MANAGER_PAGE, { replace: true });
  };

  useEffect(() => {
    if (openModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [openModal]);

  return (
    <Modal
      id="add-supporter-modal"
      show={openModal}
      size="4xl"
      onClose={() => {
        setOpenModal(!openModal);
        setIsEdit(false);
        if (isComeback) navigate(ROUTE_PATHS.SUPPORTER_MANAGER_PAGE, { replace: true });
        setIsResetForm(!isResetForm);
      }}
      dismissible={true}
    >
      <Modal.Header>{!isEdit ? t('addSupporter') : t('editSupporter')}</Modal.Header>
      <Modal.Body style={{ padding: '1.0rem' }}>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={ValidateSchema}
          setIsResetForm={setIsResetForm}
          validateOnChange={true}
          className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
        >
          {(props: FormikProps<any>) => {
            return (
              <Form className="flex flex-col gap-4">
                <div className="space-y-4 px-4 pb-2 sm:pb-6 lg:px-2 xl:pb-8">
                  <div className="form-flex-col flex w-full items-start space-x-3 ">
                    <div className="form-input w-1/3">
                      <CustomSelectForm
                        label={t('prefixTitles')}
                        id="prefixTitles"
                        name="prefixTitles"
                        placeHolder={t('prefixTitles')}
                        dataTestId="test-supporter-prefix-error"
                        options={prefixTitleOptions}
                        selectItemsHandler={(items: any, props: FormikProps<AddSupporterFormTypes>) =>
                          selectItemHandler<AddSupporterFormTypes>({ items, props, fieldName: 'prefixTitles' })
                        }
                        propsFormik={props}
                        isEdit={isEdit}
                        isMulti={false}
                        disableSearch={true}
                      />
                    </div>
                    <div className="form-input w-1/3">
                      <TextForm
                        id="firstName"
                        name="firstName"
                        label={t('firstName')}
                        placeholder={t('firstName')}
                        dataTestId="test-supporter-firstname-error"
                        propsFormik={props}
                        isEdit={isEdit}
                        isRequired={!props.values['organizationName']}
                        setCurrentValue={async (
                          text: {
                            name?: string;
                            value?: string;
                          },
                          props: FormikProps<AddSupporterFormTypes>,
                        ) => {
                          await setTextValue<AddSupporterFormTypes>({ text, props });
                        }}
                      />
                    </div>
                    <div className="form-input w-1/3">
                      <TextForm
                        id="lastName"
                        name="lastName"
                        label={t('lastName')}
                        placeholder={t('lastName')}
                        dataTestId="test-supporter-lastname-error"
                        propsFormik={props}
                        isEdit={isEdit}
                        isRequired={!props.values['organizationName']}
                        setCurrentValue={async (
                          text: {
                            name?: string;
                            value?: string;
                          },
                          props: FormikProps<AddSupporterFormTypes>,
                        ) => {
                          await setTextValue<AddSupporterFormTypes>({ text, props });
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-flex-col flex w-full items-start space-x-3">
                    <div className="form-input w-1/3 pr-1">
                      <TextForm
                        id="email"
                        name="email"
                        label={t('email')}
                        placeholder={t('email')}
                        dataTestId="test-supporter-email-error"
                        propsFormik={props}
                        isEdit={isEdit}
                        setCurrentValue={async (
                          text: {
                            name?: string;
                            value?: string;
                          },
                          props: FormikProps<AddSupporterFormTypes>,
                        ) => {
                          await setTextValue<AddSupporterFormTypes>({ text, props });
                        }}
                      />
                    </div>
                    <div className="form-input w-1/3 pr-1">
                      <CustomDatePickerForm
                        id="dateOfBirth"
                        name="dateOfBirth"
                        label={t('dateOfBirth')}
                        placeHolder={t('dateOfBirth')}
                        propsFormik={props}
                        selectItemsHandler={(date: Date) =>
                          selectDateHandler<AddSupporterFormTypes>({
                            date,
                            props,
                            fieldName: 'dateOfBirth',
                          })
                        }
                        isEdit={isEdit}
                        isDeleteSelectedDate={openModal}
                        position="top"
                        yearDropdownItemNumber={150}
                        maxDate={moment.utc().toDate()}
                      />
                    </div>
                    <div className="form-input w-1/3">
                      <TextForm
                        id="SSN"
                        name="SSN"
                        label={t('SSN')}
                        placeholder={t('SSN')}
                        propsFormik={props}
                        isRequired={false}
                        isEdit={isEdit}
                        setCurrentValue={async (
                          text: {
                            name?: string;
                            value?: string;
                          },
                          props: FormikProps<AddSupporterFormTypes>,
                        ) => {
                          await setTextValue<AddSupporterFormTypes>({ text, props });
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-flex-col flex w-full  items-start space-x-3 ">
                    <div className="form-input w-1/3">
                      <TextForm
                        id="homePhone"
                        name="homePhone"
                        label={t('homePhone')}
                        placeholder={t('homePhone')}
                        propsFormik={props}
                        isEdit={isEdit}
                        setCurrentValue={async (
                          text: {
                            name?: string;
                            value?: string;
                          },
                          props: FormikProps<AddSupporterFormTypes>,
                        ) => {
                          await setTextValue<AddSupporterFormTypes>({ text, props });
                        }}
                      />
                    </div>
                    <div className="form-input w-1/3">
                      <TextForm
                        id="businessPhone"
                        name="businessPhone"
                        label={t('businessPhone')}
                        placeholder={t('businessPhone')}
                        dataTestId="test-supporter-businessPhone-error"
                        propsFormik={props}
                        isEdit={isEdit}
                        setCurrentValue={async (
                          text: {
                            name?: string;
                            value?: string;
                          },
                          props: FormikProps<AddSupporterFormTypes>,
                        ) => {
                          await setTextValue<AddSupporterFormTypes>({ text, props });
                        }}
                      />
                    </div>
                    <div className="form-input w-1/3">
                      <TextForm
                        id="organizationName"
                        name="organizationName"
                        label={t('organizationName')}
                        placeholder={t('organizationName')}
                        dataTestId="test-supporter-organizationName-error"
                        propsFormik={props}
                        isEdit={isEdit}
                        isRequired={!(props.values['firstName'] && props.values['lastName'])}
                        setCurrentValue={async (
                          text: {
                            name?: string;
                            value?: string;
                          },
                          props: FormikProps<AddSupporterFormTypes>,
                        ) => {
                          await setTextValue<AddSupporterFormTypes>({ text, props });
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-flex-col flex w-full items-start space-x-3 row-highlight row-highlight-old">
                    <div className="form-input w-1/3">
                      <TextForm
                        id="address"
                        name="address"
                        label={t('address')}
                        placeholder={t('address')}
                        dataTestId="test-supporter-address-error"
                        propsFormik={props}
                        isEdit={isEdit}
                        isRequired={true}
                        setCurrentValue={async (
                          text: {
                            name?: string;
                            value?: string;
                          },
                          props: FormikProps<AddSupporterFormTypes>,
                        ) => {
                          await setTextValue<AddSupporterFormTypes>({ text, props });
                        }}
                      />
                    </div>
                    <div className="form-input w-1/3">
                      <TextForm
                        id="city"
                        name="city"
                        label={t('city')}
                        placeholder={t('city')}
                        propsFormik={props}
                        isEdit={isEdit}
                        isRequired={true}
                        setCurrentValue={async (
                          text: {
                            name?: string;
                            value?: string;
                          },
                          props: FormikProps<AddSupporterFormTypes>,
                        ) => {
                          await setTextValue<AddSupporterFormTypes>({ text, props });
                        }}
                      />
                    </div>
                    <div className="form-input w-1/3">
                      <TextForm
                        id="state"
                        name="state"
                        label={t('state')}
                        placeholder={t('state')}
                        dataTestId="test-supporter-state-error"
                        propsFormik={props}
                        isEdit={isEdit}
                        setCurrentValue={async (
                          text: {
                            name?: string;
                            value?: string;
                          },
                          props: FormikProps<AddSupporterFormTypes>,
                        ) => {
                          await setTextValue<AddSupporterFormTypes>({ text, props });
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-flex-col flex w-full items-start space-x-3 row-highlight row-highlight-even">
                    <div className="form-input w-1/3">
                      <TextForm
                        id="zipCode"
                        name="zipCode"
                        label={t('zipCode')}
                        placeholder={t('zipCode')}
                        dataTestId="test-supporter-zip-code-error"
                        propsFormik={props}
                        isEdit={isEdit}
                        setCurrentValue={async (
                          text: {
                            name?: string;
                            value?: string;
                          },
                          props: FormikProps<AddSupporterFormTypes>,
                        ) => {
                          await setTextValue<AddSupporterFormTypes>({ text, props });
                        }}
                      />
                    </div>
                    <div className="form-input w-1/3">
                      <CustomSelectForm
                        label={t('country')}
                        id="country"
                        name="country"
                        placeHolder={t('country')}
                        dataTestId="test-supporter-country-error"
                        options={countryOptions}
                        selectItemsHandler={(items: any, props: FormikProps<AddSupporterFormTypes>) =>
                          selectItemHandler<AddSupporterFormTypes>({ items, props, fieldName: 'country' })
                        }
                        propsFormik={props}
                        isRequired={true}
                        isEdit={isEdit}
                        isMulti={false}
                      />
                    </div>
                    <div className="form-input w-1/3">
                      <TextForm
                        id="trustName"
                        name="trustName"
                        label={t('trustName')}
                        placeholder={t('trustName')}
                        dataTestId="test-supporter-trustName-error"
                        propsFormik={props}
                        isEdit={isEdit}
                        setCurrentValue={async (
                          text: {
                            name?: string;
                            value?: string;
                          },
                          props: FormikProps<AddSupporterFormTypes>,
                        ) => {
                          await setTextValue<AddSupporterFormTypes>({ text, props });
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-flex-col flex w-full items-start space-x-3">
                    <div className="form-input w-1/2">
                      <CustomSelectForm
                        label={t('type')}
                        id="type"
                        name="type"
                        placeHolder={t('type')}
                        options={SUPPORTER_TYPE_OPTIONS}
                        dataTestId="test-supporter-type-error"
                        selectItemsHandler={(items: any, props: FormikProps<AddSupporterFormTypes>) =>
                          selectItemHandler<AddSupporterFormTypes>({ items, props, fieldName: 'type' })
                        }
                        propsFormik={props}
                        isEdit={isEdit}
                        isMulti={false}
                        disableSearch={true}
                      />
                    </div>
                    <div className="form-input w-1/2">
                      <CustomSelectForm
                        label={t('communicationPreference')}
                        id="preferredCommunicationMethod"
                        name="preferredCommunicationMethod"
                        placeHolder={t('communicationPreference')}
                        options={SUPPORTER_PREFERRED_COMMUNICATION_METHOD_OPTIONS}
                        dataTestId="test-supporter-preferredCommunicationMethod-error"
                        selectItemsHandler={(items: any, props: FormikProps<AddSupporterFormTypes>) =>
                          selectItemHandler<AddSupporterFormTypes>({
                            items,
                            props,
                            fieldName: 'preferredCommunicationMethod',
                          })
                        }
                        propsFormik={props}
                        isEdit={isEdit}
                        isMulti={false}
                        disableSearch={true}
                      />
                    </div>
                  </div>
                  <div className="form-flex-col flex w-full justify-between items-start space-x-3 row-highlight row-highlight-grey">
                    <div className="form-input w-1/3 flex flex-col justify-between" style={{ height: '54px' }}>
                      <p className="text-sm text-gray-500 leading-none">{t('Preferred Payment Method')}</p>
                      <div className="mt-2 grid grid-cols-3">
                        <CheckboxForm
                          name="preferredPaymentMethod"
                          label={SUPPORTER_PREFERRED_PAYMENT_METHOD.CHECK}
                          propsFormik={props}
                          isEdit={isEdit}
                          setCurrentValue={async (
                            text: {
                              name?: string;
                              value?: string;
                            },
                            props: FormikProps<AddSupporterFormTypes>,
                          ) => {
                            await props.setFieldValue('bankAccountNumber', '');
                            await props.setFieldValue('routingNumber', '');
                            await setTextValue<AddSupporterFormTypes>({ text, props });
                          }}
                          isRequired={false}
                        />
                        <CheckboxForm
                          name="preferredPaymentMethod"
                          label={SUPPORTER_PREFERRED_PAYMENT_METHOD.ACH}
                          propsFormik={props}
                          isEdit={isEdit}
                          setCurrentValue={async (
                            text: {
                              name?: string;
                              value?: string;
                            },
                            props: FormikProps<AddSupporterFormTypes>,
                          ) => {
                            if (!text?.value) {
                              await props.setFieldValue('bankAccountNumber', '');
                              await props.setFieldValue('routingNumber', '');
                            }
                            await setTextValue<AddSupporterFormTypes>({ text, props });
                          }}
                          isRequired={false}
                        />
                      </div>
                      {props.errors.preferredPaymentMethod && props.errors.preferredPaymentMethod && (
                        <ErrorMessage className="text-red-500 text-xs mt-1" name="preferredPaymentMethod" component="p" />
                      )}
                    </div>
                    {props.values.preferredPaymentMethod.includes(SUPPORTER_PREFERRED_PAYMENT_METHOD.ACH) && (
                      <div className="form-flex-col form-inserted w-2/3 flex space-x-3">
                        <TextForm
                          id="bankAccountNumber"
                          name="bankAccountNumber"
                          label={t('bankAccountNumber')}
                          placeholder={t('bankAccountNumber')}
                          dataTestId="test-supporter-bankAccountNumber-error"
                          propsFormik={props}
                          isEdit={isEdit}
                          setCurrentValue={async (
                            text: {
                              name?: string;
                              value?: string;
                            },
                            props: FormikProps<AddSupporterFormTypes>,
                          ) => {
                            await setTextValue<AddSupporterFormTypes>({ text, props });
                          }}
                        />
                        <TextForm
                          id="routingNumber"
                          name="routingNumber"
                          label={t('routingNumber')}
                          placeholder={t('routingNumber')}
                          dataTestId="test-supporter-routingNumber-error"
                          propsFormik={props}
                          isEdit={isEdit}
                          setCurrentValue={async (
                            text: {
                              name?: string;
                              value?: string;
                            },
                            props: FormikProps<AddSupporterFormTypes>,
                          ) => {
                            await setTextValue<AddSupporterFormTypes>({ text, props });
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <GroupButton
                  className="w-full gap-4 justify-center"
                  buttons={[
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => closeModalHandler(props),
                    },
                    {
                      type: 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                    },
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddSupporterModal;
