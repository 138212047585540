import { MutableRefObject, useContext } from 'react';
import classNames from 'classnames';
import NoResult from 'components/common/noResult';
import TooltipComponent from 'components/common/Tooltip';
import { AbilityContext } from 'contexts/can';
import { ABILITY_ACTION, PERMISSION, SUPPORTER_TYPE_OPTIONS } from 'utils/constants';
import { AddressType, ContactType, SupporterType } from 'utils/proptypes';
import { concatItemsDisplayName } from 'utils/utils';

import CustomSpinner from '../../../../components/common/customSpinner';

type RenderSupportersTableDataType = {
  tbl: MutableRefObject<null>;
  supporters: SupporterType[];
  openSupporterDetail: Function;
  isFetching: boolean;
};

const RenderSupportersTableData = (props: RenderSupportersTableDataType) => {
  const ability = useContext(AbilityContext);
  const { tbl, supporters, openSupporterDetail, isFetching } = props;
  const renderSupporterType = (type: any) => {
    if (SUPPORTER_TYPE_OPTIONS.find(item => item?.label === type)) {
      return (
        <p className={`supporter-type`}>
          <span className={`supporter-type__${type}`}>{type}</span>
        </p>
      );
    }
  };

  return (
    <div className="supporter-table__body h-full whitespace-nowrap">
      <table
        className={classNames('w-full h-[calc(100%-70px)] text-sm text-left text-gray-500 dark:text-gray-400', {
          'body-without-records': !supporters?.length,
        })}
        ref={tbl}
        data-testid="test-supporter-table"
      >
        <tbody>
          {supporters?.length ? (
            supporters?.map((item: SupporterType, key: number) => {
              return (
                <tr
                  key={`${key}-list-supporter`}
                  className={`supporter-${key === supporters?.length - 1 ? 'last' : key
                    } bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600`}
                  data-testid="test-supporter-row"
                  onClick={() => {
                    if (ability.can(ABILITY_ACTION.manage, PERMISSION.SUPPORTER.VIEW_DETAIL)) openSupporterDetail(item);
                  }}
                >
                  <th
                    scope="row"
                    className="py-2 px-3 font-normal  max-width-100 text-gray-900 supporter-page--truncate dark:text-white max-width-80"
                  >
                    <TooltipComponent
                      anchorSelect={`supporter-name-${key}`}
                      content={item?.firstName || item?.lastName ? `${item?.firstName} ${item?.lastName}`: item?.organizationName}
                      className=" font-semibold supporter-page--truncate max-w-full dark:text-white"
                    />
                    {renderSupporterType(item?.type)}
                  </th>
                  <th
                    scope="row"
                    className="py-2 px-3 font-normal text-gray-900 supporter-page--truncate max-width-100 dark:text-white hidden-mobile"
                  >
                    <TooltipComponent
                      anchorSelect={`partner-country-${key}`}
                      content={`${concatItemsDisplayName<ContactType, AddressType>({
                        items: item?.contacts,
                        argFilter: 'address',
                        argDisplay: 'zip',
                      })
                        ? `${concatItemsDisplayName<ContactType, AddressType>({
                          items: item?.contacts,
                          argFilter: 'address',
                          argDisplay: 'zip',
                        })} - `
                        : ''
                        }${concatItemsDisplayName<ContactType, AddressType>({
                          items: item?.contacts,
                          argFilter: 'address',
                          argDisplay: 'street',
                        })}${concatItemsDisplayName<ContactType, AddressType>({
                          items: item?.contacts,
                          argFilter: 'address',
                          argDisplay: 'city',
                        })
                          ? `, ${concatItemsDisplayName<ContactType, AddressType>({
                            items: item?.contacts,
                            argFilter: 'address',
                            argDisplay: 'city',
                          })}`
                          : ''
                        }${concatItemsDisplayName<ContactType, AddressType>({
                          items: item?.contacts,
                          argFilter: 'address',
                          argDisplay: 'state',
                        })
                          ? `, ${concatItemsDisplayName<ContactType, AddressType>({
                            items: item?.contacts,
                            argFilter: 'address',
                            argDisplay: 'state',
                          })}`
                          : ''
                        }${concatItemsDisplayName<ContactType, AddressType>({
                          items: item?.contacts,
                          argFilter: 'address',
                          argDisplay: 'country',
                        })
                          ? `, ${concatItemsDisplayName<ContactType, AddressType>({
                            items: item?.contacts,
                            argFilter: 'address',
                            argDisplay: 'country',
                          })}`
                          : ''
                        }`}
                      className="font-normal partners-page--truncate max-w-full dark:text-white"
                    />
                  </th>
                </tr>
              );
            })
          ) : (
            <tr className="h-full supporter-no-result text-center bg-white py-4 flex justify-center items-center">
              <th>{isFetching ? <CustomSpinner /> : <NoResult />}</th>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RenderSupportersTableData;
