import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AddUpdateLoanType, createLoan, updateLoanById } from 'api/loanFundManager/loanApi';
import GroupButton from 'components/button/groupButton';
import CustomDatePickerForm from 'components/form/dateSelectForm/customDatePickerForm';
import CustomSelectForm from 'components/form/selectForm/customSelectForm';
import CurrencyForm from 'components/form/textForm/currencyForm';
import { Modal } from 'flowbite-react';
import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { E_PAYMENT_TYPE, FORMAT_DATE_API, INTEREST_DENOMINATOR_OPTIONS } from 'utils/constants';
import { LoanType, OptionType } from 'utils/proptypes';
import {
  getEPaymentTypeOptions,
  getMonthsPerPayment,
  getNumberValueFromApi,
  getNumberValueSendToApi,
  messageErrors,
  selectDateHandler,
  selectItemHandler,
  setCurrencyValue,
} from 'utils/utils';
import * as Yup from 'yup';

import AddLoanAlertModal from './addLoanAlertModal';
import ConfirmEditLoanModal from './confirmEditLoanModal';

import './addLoanModal.scss';
import '../../../styles/common/multiSelect.scss';

type EditTermsLoanModalTypes = {
  openModal: boolean;
  setOpenModal: Function;
  selectedLoan?: LoanType;
  refetchApi: Function;
  fetchLoans?: Function;
};

type EditTermsLoanFormTypes = {
  amount: number;
  adminFee: number;
  interestRate: number;
  firstInterestPayment: string;
  firstPrincipalPayment: string;
  nonPerformingDate: string;
  disbursementDate: string;
  term: number;
  maturity: string;
  payInterest: OptionType[];
  payPrincipal: OptionType[];
  interestDenominator: OptionType[];
};

const EditTermsLoanModal = (props: EditTermsLoanModalTypes) => {
  const { openModal = false, setOpenModal, selectedLoan, refetchApi, fetchLoans} = props;
  const { t } = useTranslation();
  const [isResetForm, setIsResetForm] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openAlertModal, setOpenAlertModal] = useState(false)
  const params = useParams();
  const queryClient = useQueryClient();
  const partnerId = params.partnerId;
  const [isShowInitialDate, setIsShowInitialDate] = useState(true);
  const [data, setData] = useState<any>(null)
  const [action, setAction] = useState<any>(null)
  const ValidateSchema = Yup.object().shape({
    amount: Yup.number()
      .min(0, t('loanPage.amountMin'))
      .max(1000000000, t('loanPage.amountMax'))
      .nullable()
      .required(t('loanPage.requiredField'))
      .typeError(t('loanPage.requiredField')),
    adminFee: Yup.number().min(0, t('loanPage.adminFeeMin')).max(100, t('loanPage.adminFeeMax')).nullable(),
    interestRate: Yup.number()
      .min(0, t('loanPage.rateLengthMin'))
      .max(20, t('loanPage.rateLengthMax'))
      .nullable()
      .required(t('loanPage.requiredField'))
      .typeError(t('loanPage.requiredField')),
    disbursementDate: Yup.date().nullable().required(t('loanPage.requiredField')),
    term: Yup.number()
      .min(0, t('loanPage.termLengthMin'))
      .max(240, t('loanPage.termLengthMax'))
      .nullable()
      .required(t('loanPage.requiredField'))
      .typeError(t('loanPage.requiredField')),
    maturity: Yup.date().nullable().required(t('loanPage.requiredField')),
    firstInterestPayment: Yup.date()
      .nullable()
      .required(t('loanPage.requiredField'))
      .test({
        name: 'firstInterestPayment-test',
        skipAbsent: true,
        test(value, ctx) {
          const {
            createError,
            parent: { disbursementDate },
          } = ctx;
          if (value < disbursementDate) return createError({ message: t('loanPage.firstPaymentMin') });
          return true;
        },
      }),
    firstPrincipalPayment: Yup.date()
      .nullable()
      .required(t('loanPage.requiredField'))
      .test({
        name: 'firstPrincipalPayment-test',
        skipAbsent: true,
        test: (value, ctx) => {
          const {
            createError,
            parent: { disbursementDate },
          } = ctx;
          if (value && value < disbursementDate) return createError({ message: t('loanPage.firstPaymentMin') });
          return true;
        },
      }),
    payInterest: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string().required(t('loanPage.rateTypeIsString')),
        }),
      )
      .min(1, t('loanPage.requiredField')),
    payPrincipal: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string().required(t('loanPage.rateTypeIsString')),
        }),
      )
      .min(1, t('loanPage.requiredField')),
    interestDenominator: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.number().required(t('loanPage.requiredField')),
        }),
      )
      .min(1, t('loanPage.requiredField')),
  });

  const initialValues: any = useMemo(() => {
    return {
        amount: selectedLoan?.amount ? getNumberValueFromApi(selectedLoan?.amount) : '',
        adminFee: selectedLoan?.adminFee ? getNumberValueFromApi(selectedLoan?.adminFee) : '',
        interestRate: selectedLoan?.interestRate ? getNumberValueFromApi(selectedLoan?.interestRate) : '',
        firstInterestPayment: selectedLoan?.firstInterestPayment ? moment(selectedLoan?.firstInterestPayment).toDate() : '',
        firstPrincipalPayment: selectedLoan?.firstPrincipalPayment ? moment(selectedLoan?.firstPrincipalPayment).toDate() : '',
        maturity: selectedLoan?.maturity ? moment(selectedLoan?.maturity).toDate() : '',
        disbursementDate: selectedLoan?.disbursementDate ? moment(selectedLoan?.disbursementDate).toDate() : '',
        term: selectedLoan?.term ? selectedLoan?.term : '',
        payInterest: selectedLoan?.payInterest ? getEPaymentTypeOptions().filter(item => item.value === selectedLoan?.payInterest) : [],
        payPrincipal: selectedLoan?.payPrincipal ? getEPaymentTypeOptions().filter(item => item.value === selectedLoan?.payPrincipal) : [],
        interestDenominator: selectedLoan?.interestDenominator
          ? INTEREST_DENOMINATOR_OPTIONS.filter(item => item.value === selectedLoan?.interestDenominator)
          : [],
      };
  }, [selectedLoan]);

  const handleEditTerms = async (loanPayload: AddUpdateLoanType) => {
      await updateLoanById(selectedLoan?.id as string, loanPayload);

  };

  const handleAlertOK = () => {
    setOpenConfirmModal(true);
  }

  const mutation = useMutation('edit-terms-loan', {
    mutationFn: handleEditTerms,
  });

  const onSubmit = (data: EditTermsLoanFormTypes,
    action: {
      [key: string]: any;
    },) => {
    setOpenAlertModal(true)
    setData(data)
    setAction(action)
  }
  const handleSubmit = (
    data: EditTermsLoanFormTypes,
    action: {
      [key: string]: any;
    },
  ) => {
    const successCallback = async (message: string) => {
      setOpenModal(!openModal);
      setIsShowInitialDate(false);
      toast.success(message);
      action.resetForm();
      refetchApi();
      if (typeof fetchLoans === 'function') fetchLoans();
      await queryClient.invalidateQueries('paymentsSchedule');
    };
    const tempData = {
      partnerId,
      amount: getNumberValueSendToApi(data.amount),
      adminFee: data.adminFee ? getNumberValueSendToApi(data.adminFee) : null,
      interestRate: getNumberValueSendToApi(data.interestRate),
      firstInterestPayment: data.firstInterestPayment ? moment(data.firstInterestPayment).format(FORMAT_DATE_API) : null,
      firstPrincipalPayment: data.firstPrincipalPayment ? moment(data.firstPrincipalPayment).format(FORMAT_DATE_API) : null,
      disbursementDate: moment(data.disbursementDate).format(FORMAT_DATE_API),
      term: data.term,
      maturity: data.maturity ? moment(data.maturity).format(FORMAT_DATE_API) : null,
      payInterest: data.payInterest[0].value,
      payPrincipal: data.payPrincipal[0].value,
      interestDenominator: data.interestDenominator[0].value,
    };

    if (openModal) {
      mutation.mutate(
        {
          ...(tempData as any),
        },
        {
          onSuccess: async () => {
            const message: string = t('loanPage.editSuccessMessage');
            await successCallback(message);
          },
          onError: async (error: any) => {
            const message: string = messageErrors(error, t);
            toast.error(message);
          },
        },
      );
    }
  };

  const closeModalHandler = (props: FormikProps<EditTermsLoanFormTypes>) => {
    setOpenModal(!openModal);
    setIsShowInitialDate(false);
    props.resetForm();
    props.setErrors({});
  };

  useEffect(() => {
    if (openModal) {
      setIsResetForm(!isResetForm);
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, setIsResetForm]);

  return (
    <Modal
      id="edit-terms-loan-modal"
      show={openModal}
      size="4xl"
      onClose={() => {
        setOpenModal(false);
        setIsResetForm(!isResetForm);
      }}
      dismissible={true}
    >
      <Modal.Header>{t('editLoanTerms')}</Modal.Header>
      <Modal.Body>
        <Formik enableReinitialize onSubmit={onSubmit} initialValues={initialValues} validationSchema={ValidateSchema} validateOnChange={true}>
          {(props: FormikProps<any>) => {
            return (
              <Form className="flex flex-col gap-4">
                <div className="space-y-4 px-4 pb-2 sm:pb-6 lg:px-2 xl:pb-8">
                  <div className="form-flex-col flex justify-between items-start space-x-2">
                    <CurrencyForm
                      id="amount"
                      name="amount"
                      label={t('amount')}
                      placeholder={t('amount')}
                      propsFormik={props}
                      setCurrentValue={async (
                        currency: {
                          name?: string;
                          value?: string;
                        },
                        props: FormikProps<EditTermsLoanFormTypes>,
                      ) => {
                        await setCurrencyValue<EditTermsLoanFormTypes>({ currency, props });
                      }}
                      isRequired={true}
                      unit="$"
                      styleUnit="prefix"
                      decimalLimit={2}
                    />
                    <CurrencyForm
                      id="adminFee"
                      name="adminFee"
                      label={t('adminFee')}
                      placeholder={t('adminFee')}
                      propsFormik={props}
                      setCurrentValue={async (
                        currency: {
                          name?: string;
                          value?: string;
                        },
                        props: FormikProps<EditTermsLoanFormTypes>,
                      ) => {
                        await setCurrencyValue<EditTermsLoanFormTypes>({ currency, props });
                      }}
                      unit="%"
                      styleUnit="prefix"
                      decimalLimit={2}
                    />
                    <CurrencyForm
                      id="interestRate"
                      name="interestRate"
                      label={t('interestRate')}
                      placeholder={t('interestRate')}
                      propsFormik={props}
                      setCurrentValue={async (
                        currency: {
                          name?: string;
                          value?: string;
                        },
                        props: FormikProps<EditTermsLoanFormTypes>,
                      ) => {
                        await setCurrencyValue<EditTermsLoanFormTypes>({ currency, props });
                      }}
                      isRequired={true}
                      unit="%"
                      styleUnit="suffix"
                      decimalLimit={2}
                    />
                  </div>
                  <div className="form-flex-col flex flex-column justify-between items-start space-x-2">
                    <CustomDatePickerForm
                      id="disbursementDate"
                      name="disbursementDate"
                      label={t('disbursementDate')}
                      placeHolder={t('disbursementDate')}
                      propsFormik={props}
                      selectItemsHandler={async (date: Date) => {
                        await props.setFieldTouched('firstPrincipalPayment', date <= props.values.firstPrincipalPayment);
                        await selectDateHandler<EditTermsLoanFormTypes>({ date, props, fieldName: 'disbursementDate' });
                      }}
                      isRequired={true}
                      isDeleteSelectedDate={openModal}
                      setIsShowInitial={setIsShowInitialDate}
                      dataTestId="disbursementDate"
                    />
                    <CurrencyForm
                      id="term"
                      name="term"
                      label={t('term')}
                      placeholder={t('term')}
                      propsFormik={props}
                      setCurrentValue={async (
                        currency: {
                          name?: string;
                          value?: string;
                        },
                        props: FormikProps<EditTermsLoanFormTypes>,
                      ) => {
                        await setCurrencyValue<EditTermsLoanFormTypes>({ currency, props });
                        setIsShowInitialDate(true);
                      }}
                      isRequired={true}
                      unit={t('months')}
                      styleUnit="suffix"
                      maxLength={3}
                      dataTestId="term"
                    />
                    <CustomDatePickerForm
                      id="maturity"
                      name="maturity"
                      label={t('maturity')}
                      placeHolder={t('maturity')}
                      defaultValue={props.getFieldProps('disbursementDate').value}
                      termValue={props.getFieldProps('term').value}
                      propsFormik={props}
                      selectItemsHandler={(date: Date) =>
                        selectDateHandler<EditTermsLoanFormTypes>({
                          date,
                          props,
                          fieldName: 'maturity',
                        })
                      }
                      isRequired={true}
                      isDisabled={false}
                      isDeleteSelectedDate={openModal}
                      isShowInitial={isShowInitialDate}
                      minDate={props.values.disbursementDate}
                      dataTestId="maturity"
                    />
                  </div>
                  <div className="form-flex-col flex flex-column justify-between items-start space-x-2">
                    <CustomSelectForm
                      label={t('payInterest')}
                      id="payInterest"
                      name="payInterest"
                      placeHolder={t('payInterest')}
                      options={getEPaymentTypeOptions()}
                      selectItemsHandler={async (items: any, props: FormikProps<EditTermsLoanFormTypes>) => {
                        await selectItemHandler<EditTermsLoanFormTypes>({ items, props, fieldName: 'payInterest' });
                        setIsShowInitialDate(true);
                      }}
                      propsFormik={props}
                      isRequired={true}
                      isMulti={false}
                      disableSearch={true}
                    />
                    <CustomSelectForm
                      label={t('payPrincipal')}
                      id="payPrincipal"
                      name="payPrincipal"
                      placeHolder={t('payPrincipal')}
                      options={getEPaymentTypeOptions()}
                      selectItemsHandler={async (items: any, props: FormikProps<EditTermsLoanFormTypes>) => {
                        await selectItemHandler<EditTermsLoanFormTypes>({ items, props, fieldName: 'payPrincipal' });
                        setIsShowInitialDate(true);
                      }}
                      propsFormik={props}
                      isRequired={true}
                      isMulti={false}
                      disableSearch={true}
                    />
                    <CustomSelectForm
                      label={t('interestDenominator')}
                      id="interestDenominator"
                      name="interestDenominator"
                      placeHolder={t('interestDenominator')}
                      options={INTEREST_DENOMINATOR_OPTIONS}
                      selectItemsHandler={(items: any, props: FormikProps<EditTermsLoanFormTypes>) =>
                        selectItemHandler<EditTermsLoanFormTypes>({ items, props, fieldName: 'interestDenominator' })
                      }
                      propsFormik={props}
                      isRequired={true}
                      isMulti={false}
                      disableSearch={true}
                    />
                  </div>
                  <div className="form-flex-col flex justify-start items-start space-x-2">
                    <div className="w-2/3">
                      <CustomDatePickerForm
                        id="firstInterestPayment"
                        name="firstInterestPayment"
                        dataTestId="firstInterestPayment"
                        label={t('firstInterestPayment')}
                        placeHolder={t('firstInterestPayment')}
                        defaultValue={props.getFieldProps('disbursementDate').value}
                        termValue={getMonthsPerPayment({
                          monthsPerPaymentType: props.getFieldProps('payInterest').value?.[0]?.value,
                          term: props.getFieldProps('term').value,
                        })}
                        propsFormik={props}
                        selectItemsHandler={async (date: Date) => {
                          await props.setFieldTouched('firstInterestPayment', date < props.values.disbursementDate && true);
                          await selectDateHandler<EditTermsLoanFormTypes>({ date, props, fieldName: 'firstInterestPayment' });
                        }}
                        isRequired={true}
                        isDeleteSelectedDate={openModal}
                        isShowInitial={isShowInitialDate}
                        minDate={props.values.disbursementDate}
                      />
                    </div>
                    <div className="w-1/3">
                      <CustomDatePickerForm
                        id="firstPrincipalPayment"
                        name="firstPrincipalPayment"
                        dataTestId="firstPrincipalPayment"
                        label={t('firstPrincipalPayment')}
                        placeHolder={t('firstPrincipalPayment')}
                        defaultValue={props.getFieldProps('disbursementDate').value}
                        termValue={getMonthsPerPayment({
                          monthsPerPaymentType: props.getFieldProps('payPrincipal').value?.[0]?.value,
                          term: props.getFieldProps('term').value,
                        })}
                        propsFormik={props}
                        selectItemsHandler={async (date: Date) => {
                          await props.setFieldTouched('firstPrincipalPayment', date < props.values.disbursementDate && true);
                          await selectDateHandler<EditTermsLoanFormTypes>({ date, props, fieldName: 'firstPrincipalPayment' });
                        }}
                        isRequired={true}
                        isDeleteSelectedDate={openModal}
                        isShowInitial={isShowInitialDate}
                        minDate={props.values.disbursementDate}
                      />
                    </div>
                  </div>
                  <GroupButton
                    className="w-full gap-2 justify-center pb-2"
                    buttons={[
                      {
                        type: 'button',
                        text: t('modal.cancel'),
                        classType: 'white',
                        action: () => closeModalHandler(props),
                      },
                      {
                        type: 'submit',
                        text: t('modal.save'),
                        classType: 'blue',
                      },
                    ]}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
        <AddLoanAlertModal
          openConfirmModal={openAlertModal}
          setOpenConfirmModal={setOpenAlertModal}
          handleSubmit={handleAlertOK}
          data={selectedLoan}
        />
        <ConfirmEditLoanModal
          openConfirmModal={openConfirmModal}
          setOpenConfirmModal={setOpenConfirmModal}
          handleSubmit={handleSubmit}
          data={data}
          action={action}
        />
      </Modal.Body>
    </Modal>
  );
};

export default EditTermsLoanModal;
