import { useTranslation } from "react-i18next";
import GroupButton from 'components/button/groupButton';
import { Modal } from "flowbite-react";
import moment from 'moment/moment';

import { FORMAT_DATE_SHOW_TABLE } from '../../../utils/constants';

import './confirmEditLoanModal.scss'

type AddLoanAlertModalTypes = {
  openConfirmModal: boolean;
  setOpenConfirmModal: Function;
  handleSubmit: Function;
  data: any;
};

const AddLoanAlertModal = (props: AddLoanAlertModalTypes) => {
  const { openConfirmModal, setOpenConfirmModal, handleSubmit, data} = props
  const [t] = useTranslation()

  return (
    <Modal
      id="confirm-edit-loan-modal"
      show={openConfirmModal}
      size="xl"
      onClose={() => {
        setOpenConfirmModal(false)
      }}
      dismissible={true}
    >
      <Modal.Header>{''}</Modal.Header>
      <Modal.Body className="pb-6">
        <div className="text-center mb-6" dangerouslySetInnerHTML={{__html:t('Please ensure the Disbursement, First Interest, and First Principal Payments are correct.')}}/>
        <div className="text-center" >{t('disbursement')}: {data?.disbursementDate ? moment(data?.disbursementDate).format(FORMAT_DATE_SHOW_TABLE) : ''}</div>
        <div className="text-center" >{t('firstInterest')}: {data?.firstInterestPayment ? moment(data?.firstInterestPayment).format(FORMAT_DATE_SHOW_TABLE) : ''}</div>
        <div className="text-center mb-6" >{t('firstPrincipal')}: {data?.firstPrincipalPayment ? moment(data?.firstPrincipalPayment).format(FORMAT_DATE_SHOW_TABLE) : ''}</div>
        <GroupButton
          className="w-full gap-2 justify-center pb-2"
          buttons={[
            {
              type: 'button',
              text: t('modal.cancel'),
              classType: 'white',
              action: () => setOpenConfirmModal(false),
            },
            {
              type: 'button',
              dataTestId: "alert-ok-button",
              text: t('OK'),
              classType: 'blue',
              action: () => {
                handleSubmit();
                setOpenConfirmModal(false)
              }
            },
          ]}
        />
      </Modal.Body>
    </Modal>
  )
}

export default AddLoanAlertModal
